import { donationAx, defaultCountAx, publicAx } from "services/index";

export default class DonationService {
  donation(payload) {
    return donationAx.post(`donations/donate`, payload);
  }

  getDonorSaplingCount(type) {
    return defaultCountAx.get(`total-count-of-donor-and-sapling/${type}`);
  }

  getProjectList() {
    return defaultCountAx.get(
      `projects?pageNumber=0&pageSize=10&name&status&toBeFiltered=false`
    );
  }

  saveDonorSaplingDetail(payload) {
    return donationAx.post(`donations/donorSaplingDetail`, payload);
  }

  getPublicRecentPlantation(payLoad) {
    return publicAx.post("recent-plantations", payLoad);
  }

  // for species display page
  getSpeciesDetails(slugVal) {
    return defaultCountAx.get(`species/slug/${slugVal}`);
  }
}
