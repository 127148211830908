const interceptor = (ax) => {
  ax.interceptors.request.use(
    (config) => {
      let adminCheck = typeof window !== 'undefined' && window.location.pathname.includes("/admin");
      let stringfyVal
      if (adminCheck === true) {
        stringfyVal = (typeof window !== 'undefined' && localStorage.getItem('hfnAuthUserDetailsAdmin') !== "") ? localStorage.getItem('hfnAuthUserDetailsAdmin') : null;
      } else {
        stringfyVal = (typeof window !== 'undefined' && localStorage.getItem('hfnAuthUserDetails') !== "") ? localStorage.getItem('hfnAuthUserDetails') : null;
      }
      let parseData = JSON.parse(stringfyVal)
      // const firebaseId = parseData !== "" ? parseData.srcmProfile : "";
      //const token = parseData !== "" ? parseData.srcmToken : "";
      // const token = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjNmNjcyNDYxOTk4YjJiMzMyYWQ4MTY0ZTFiM2JlN2VkYTY4NDZiMzciLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiWW9nZXN3YXJhbiBOIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL3VuaWZpZWRwbGF0Zm9ybS1kZXYiLCJhdWQiOiJ1bmlmaWVkcGxhdGZvcm0tZGV2IiwiYXV0aF90aW1lIjoxNjY2Nzg1MDg4LCJ1c2VyX2lkIjoiZXplQUZJUFRkc09UN0htMUN1OTFlRlYzcXh2MiIsInN1YiI6ImV6ZUFGSVBUZHNPVDdIbTFDdTkxZUZWM3F4djIiLCJpYXQiOjE2NjY3ODUwODksImV4cCI6MTY2Njc4ODY4OSwiZW1haWwiOiJhc2FueW9nZXNoQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImFzYW55b2dlc2hAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.TErcSpv0lVIemKv-dqHhhBqkqXej38S9U0_yAS44HpHiBDraGS9WplWX2dcugMK3jjoKwp1TauqRve9crf8tm7XQcrZbRIEKJ0VUty4uJQSmTZdqEwS2A-7uXrVvPDpmokRbK3ys8CssiN8OQ4jY2jyVkTSbMvT-IetyOcroqXkWhyiXeIEGoIZLeac-tRg4ctPE4d5bdoJ4cDze07NKXfGs0Zk8Ca5fqR2PRQIUTXFCcrwQrcez0JND59Mp1s7YjjT0g1djHyopTT_JDpeDZM8SpFFQEiR9597JpO84HW7BiSx9WTPp0pEWrzPrZw1yEOg8eTNkj9tt_ObD1M0yFA"

      const token = parseData?.srcmToken
      config.headers = {
        //  "x-endpoint-api-userinfo": btoa(`{"id": "${firebaseId.firebase_uid}"}`),
        Authorization: token,
        "Content-Type": "application/json",
        "Accept": "application/json",
      };
      return config;
    },

    (error) => {
      Promise.reject(error);
    }
  );

  ax.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default interceptor;
