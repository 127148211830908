const configData = process.env;

const config = {};

//const environment = 'staging';
const environment = process.env.GATSBY_ENV;

if(environment=='staging') {
  config.apiDonationURL = configData.REACT_APP_API_BASE_URI || 'https://donation-service.qa.heartfulnessinstitute.in/';
  config.apiURL = configData.REACT_APP_API_BASE_URI || 'https://forest-by-heartfulness-service-grpc.qa.heartfulnessinstitute.in/';
  config.srcmApi = configData.REACT_APP_API_SRCM_API_URI || 'https://static-gatsby-qa.web.app/srcmapi/';
  config.currencyApi = configData.REACT_APP_API_CURRENCY_API_URI || 'https://static-gatsby-qa.web.app/rates/';
  config.donationSuccessRedirect =
    configData.REACT_APP_DONATION_SUCCESS_REDIRECT || 'https://awsstaging.heartfulness.org/forests/donation/success/';
  config.giftingSuccessRedirect =
    configData.REACT_APP_GIFTING_SUCCESS_REDIRECT || 'https://awsstaging.heartfulness.org/forests/gifting/success/';
  config.donationFailureRedirect = configData.REACT_APP_DONATION_FAILURE || 'https://awsstaging.heartfulness.org/forests/failure/';
  config.donorsCount = configData.REACT_APP_DONORS_COUNT || 356;
  config.saplingsCount = configData.REACT_APP_SAPLINGS_COUNT || 85685;
  config.saplingsPrice = configData.REACT_APP_SAPLINGS_PRICE || 180;
  // config.awGtagCode = configData.AWS_GTAG_CODE || 'AW-854824176/pzEJCL2dj_8CEPCpzpcD';
  config.awGtagCode = '';
  config.defaultCountApi = configData.DEFAULT_COUNT_API || 'https://static-file.web.app/conf/forest/defaultcount.json';
  config.srcmApiKey = configData.REACT_APP_SRCM_API_KEY || 'AIzaSyDvmxKH7738HE1T3fpJYUvi4BcEAakqQbQ';
  config.srcmAuthDomain = configData.REACT_APP_SRCM_AUTH_DOMAIN || 'auth-qa.heartfulness.org';
  config.srcmProjectId = configData.REACT_APP_SRCM_PROJECT_ID || 'unifiedplatform-qa';
  config.srcmStorageBucket = configData.REACT_APP_SRCM_STORAGE_BUCKET || 'hfn-registration-qa.appspot.com';
  config.srcmMessagingSenderId = configData.REACT_APP_SRCM_MESSAGE_SENDER_ID || '117921689659';
  config.srcmAppId = configData.REACT_APP_SRCM_APP_ID || '1:117921689659:web:6791beccca0944602cec1e';
  config.srcmDatabaseURL = configData.REACT_APP_SRCM_DATABASE_URL || 'https://hfn-registration-qa.firebaseio.com';
  config.srcmClientId = configData.REACT_APP_SRCM_CLIENT_ID || 'sNoCucDYc1ok5D8HzktKJUROtXGlD49tSGIPiXzn';
  config.srcmApiUri = configData.REACT_APP_SRCM_API_URL || 'https://profile.srcm.net';
  config.googleMapKey = configData.GOOGLE_MAP_API_KEY ||'AIzaSyBsT8BoWIPL2fCb-uVVsiIXS1JVmTB9c48';
  config.prefixPath = "/";
  config.donationMainStartDate =
    configData.GATSBY_APP_MAINTENANCE_START || "02/15/2023";
  config.donationMainEndDate =
    configData.GATSBY_APP_MAINTENANCE_END || "02/17/2023";
  config.websiteUrl =
    configData.REACT_WEBSITE_URI || "https://forest.staging.heartfulness.org/";
} else {
  config.apiDonationURL = configData.REACT_APP_API_BASE_URI || 'https://donation-service.heartfulnessinstitute.in/';
  config.apiURL = configData.REACT_APP_API_BASE_URI || 'https://forest-by-heartfulness-service-grpc.heartfulnessinstitute.in/';
  config.srcmApi = configData.REACT_APP_API_SRCM_API_URI || 'https://static-gatsby.web.app/srcmapi/';
  config.currencyApi = configData.REACT_APP_API_CURRENCY_API_URI || 'https://static-gatsby.web.app/rates/';
  config.donationSuccessRedirect =
    configData.REACT_APP_DONATION_SUCCESS_REDIRECT || 'https://heartfulness.org/forests/donation/success/';
  config.giftingSuccessRedirect =
    configData.REACT_APP_GIFTING_SUCCESS_REDIRECT || 'https://heartfulness.org/forests/gifting/success/';
  config.donationFailureRedirect = configData.REACT_APP_DONATION_FAILURE || 'https://heartfulness.org/forests/failure/';
  config.donorsCount = configData.REACT_APP_DONORS_COUNT || 356;
  config.saplingsCount = configData.REACT_APP_SAPLINGS_COUNT || 85685;
  config.saplingsPrice = configData.REACT_APP_SAPLINGS_PRICE || 180;
  config.awGtagCode = 'AW-881209313/qW7vCJi0-b8DEOHfmKQD';
  config.defaultCountApi = configData.DEFAULT_COUNT_API || 'https://static-file.web.app/conf/forest/defaultcount.json';
  config.srcmApiKey = configData.REACT_APP_SRCM_API_KEY || 'AIzaSyC_D--AX_STmQi4Ew6zbpKNFxkiCQpevHE';
  config.srcmAuthDomain = configData.REACT_APP_SRCM_AUTH_DOMAIN || 'auth.heartfulness.org';
  config.srcmProjectId = configData.REACT_APP_SRCM_PROJECT_ID || 'unifiedplatform';
  config.srcmStorageBucket = configData.REACT_APP_SRCM_STORAGE_BUCKET || 'hfn-registration.appspot.com';
  config.srcmMessagingSenderId = configData.REACT_APP_SRCM_MESSAGE_SENDER_ID || '546875419081';
  config.srcmAppId = configData.REACT_APP_SRCM_APP_ID || '1:546875419081:web:d89d88a740b17bbc';
  config.srcmDatabaseURL = configData.REACT_APP_SRCM_DATABASE_URL || 'https://hfn-registration.firebaseio.com';
  config.srcmClientId = configData.REACT_APP_SRCM_CLIENT_ID || 'smXJ4BeUn5D4V8RWRmeRvFNZGJfRwdHzjWflCP90';
  config.srcmApiUri = configData.REACT_APP_SRCM_API_URL || 'https://profile.sahajmarg.org';
  config.googleMapKey = configData.GOOGLE_MAP_API_KEY ||'AIzaSyAMl4VLu05Ui0dLD0YQe5Bt2lBt9y0LHD0';
  config.prefixPath = "/forests";
  config.donationMainStartDate =
    configData.GATSBY_APP_MAINTENANCE_START || "02/15/2023";
  config.donationMainEndDate =
    configData.GATSBY_APP_MAINTENANCE_END || "02/17/2023";
  config.websiteUrl =
    configData.REACT_WEBSITE_URI || "https://heartfulness.org/forests/";

}

module.exports = config;
