import React, { useEffect } from 'react';

import Header from './Header';
import Footer from './Footer';

import ScrollToTop from 'react-scroll-to-top';

const Layout = ({ children, isSticky, wrapperClass }) => {
  useEffect(() => {}, []);

  return (
    <div className={wrapperClass}>
      <Header isSticky={isSticky} />
      <main className='main-wrapper' id='main-wrapper'>
        {children}
      </main>
      <Footer />
      <ScrollToTop smooth className='fbh-scroll-to-top' />
    </div>
  );
};

export default Layout;
