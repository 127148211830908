import React, { useState, useEffect } from "react";

import { SRLWrapper } from "simple-react-lightbox";

import DonationService from "services/donations/donation.service";

export default function RecentPlantationsGallery() {
  const [apiGallareyImages, setApiGallareyImages] = useState([]);

  const ds = new DonationService();

  const getPublicPlantationImages = () => {
    const inputs = {
      pageNumber: 0,
      pageSize: 10,
      recentPlantationListFilter: {
        status: "ACTIVE",
      },
      sortingFieldName: "CREATED_DATE",
      sortingOrder: "ASCENDING",
    };
    ds.getPublicRecentPlantation(inputs)
      .then((res) => {
        setApiGallareyImages(
          res.data.recentPlantation?.filter((item) => {
            return item?.imageResource;
          })
        );
      })
      .catch(() => {});
  };

  useEffect(() => {
    getPublicPlantationImages();
  }, []);

  const ApiGallery = apiGallareyImages.map((item) => {
    return {
      ...item,
      imageResource: item?.imageResource?.[0],
    };
  });

  const result = [].concat
    .apply([], ApiGallery)
    .map((item) => {
      return {
        order: item.order,
        title: item.title,
        description: item.description,
        imageId: item.imageResource.id,
        img: item.imageResource.imageUrl,
        status: item.status,
        plantationId: item.id,
      };
    })
    .sort(function (a, b) {
      return a.order - b.order;
    });

  return (
    <div className="recent-plantation-section recent-plantation-gallery inner-pages pb-0">
      <div className="container">
        <div className="row ">
          <div className="col-md-12">
            <section className="section">
              <div className="container gallery">
                <div className="row">
                  <div className="col-md-12">
                    <div className="section-title">
                      <h3 className="text-start mb-5">
                        Recent{" "}
                        <span className="green">plantations Gallery</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <SRLWrapper>
                  <div className="row">
                    {result.map((item) => {
                      return (
                        <div
                          className="gallery-item col-lg-3 col-md-4 col-sm-4 col-xs-6"
                          key={item.id}
                        >
                          <div className="img-wrapper">
                            <img
                              data-src={item.img}
                              className="lazyload"
                              alt={item.description}
                            />
                            <div className="overlay">
                              <i className="icon icon-arrows-fullscreen"></i>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </SRLWrapper>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
