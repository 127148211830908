import React from 'react';

import Layout from 'layout/index';

import SEO from 'components/seo/index';

import RecentPlantationsGallery from 'components/gallery/RecentPlantationsGallery';

import SimpleReactLightbox from 'simple-react-lightbox';

// markup
const gallery = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness | gallery' />
      <Layout>
        <SimpleReactLightbox>
          <RecentPlantationsGallery />
        </SimpleReactLightbox>
      </Layout>
    </>
  );
};

export default gallery;
