import config from 'assets/config';

import interceptor from './interceptor';
const axios = require('axios');

const srcmAx = axios.create({
  baseURL: config.srcmApi,
});

const currencyAx = axios.create({
  baseURL: config.currencyApi,
});

const ax = axios.create({
  baseURL: config.apiURL,
});

const defaultCountAx = axios.create({
  baseURL: config.apiURL,
});

const donationAx = axios.create({
  baseURL: config.apiDonationURL,
});

const publicAx = axios.create({
  baseURL: config.apiURL,
});

export { srcmAx, ax, currencyAx, donationAx, defaultCountAx, publicAx };

interceptor(ax);
